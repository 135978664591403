/* eslint-disable */
import MedicationIcon from '@mui/icons-material/Medication';
import HistoryIcon from '@mui/icons-material/History';
import SickIcon from '@mui/icons-material/Sick';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export interface SocialHxActiveProps {
  Bin: {
    [key: string]: string | undefined;
    Marital_Status: string;
    Identity: string;
    Occupation: string;
    Occupation_Type: string;
    Education: string;
    Diet: string;
    diet_specifics: string;
    Exercise: string;
    Exercise_frequency: string;
    Exercise_duration: string;
    Routine: string;
    Sleep: string;
    sleep_specifics: string;
    sleep_quality: string;
    sleep_duration: string;
    Stress: string;
    stressors: string;
    stress_coping: string;
  }
}

export interface SocialHxInActiveProps {
  Bin: {
    Personal: {
      Marital_Status: string;
      Occupation_and_Education: string;
    };
    LifeStyle: {
      Dietary_Habits: string;
      Physical_Activity: string;
      Routine: string;
      Sleep_Routine: string;
      Stresses: string;
    };
  };
}

export interface MedsInActiveProps {
  Bin: {
    Trade_name: string;
    Salt: string;
    Number: string;
    Unit: string;
    Route: string;
    Frequency: string;
    Start_date: string;
    End_date: string;
  };
}



export interface MedsTradeProps {
  Trade: {
    Salt: string;
    Number: string;
    Unit: string;
    Route: string;
    Frequency: string;
    Start_date: string;
    End_date: string;
  }
}

export interface AllergiesInActiveProps {
  Bin: {
    Allergen: string;
    Allergen_Type: string;
    Reaction: string;
    Category: string;
    Start_Date: string;
    End_Date: string;

  }
}

export const binTitleMap: { [key: number]: string } = {
    1: "allergies",
    2: "drugs",
    3: "hopi",
    4: "meds",
    5: "obgyn",
    6: "screenings",
    7: "socialHx",
    8: "surgicalHx",
    9: "pastMedicalHx",
    10: "vaccine",
    11: "a_p",
    12: "familyHx"
  };

export const getDummyData = (bin: string) => {
    const emptyBins: any = {
      socialHx: {
        Marital_Status: 'Married',
        Identity: 'Hetrosexual',
        Occupation: 'Teacher',
        Occupation_Type: 'Full time',
        Education: "Post-grad",
        Diet: 'Irregular diet',
        diet_specifics: 'no meat, no grains',
        Exercise: 'Running and jogging',
        Exercise_frequency: '6 days per week',
        Exercise_duration: '45 minutes',
        Routine: 'Irregular Routine',
        Sleep: 'Irregular Sleep',
        sleep_specifics: 'Patient has sleep apnea',
        sleep_quality: 'Poor sleep quality',
        sleep_duration: '4 hours',
        Stress: 'High stress',
        stressors: 'Medical condition',
        stress_coping: 'Patient uses yoga',
      },
      meds: [ //from model
        {
          Trade_name: 'Pulmicort',
          Salt: 'pulmicort',
          Number: '0.5',
          Unit: 'mg',
          Route: 'oral',
          Frequency: '4 hours',
          Start_date: '2021-10-10',
          End_date: '2021-10-20',
        },
        {
        Trade_name: "Sertraline",
        Salt: '',
        Number: '25',
        Unit: '',
        Route: 'oral',
        Frequency: '',
        Start_date: '',
        End_date: '2021-10-20',
      },
        {
        Trade_name: 'Metformin',
        Salt: 'Glucophage',
        Number: '500',
        Unit: 'mg',
        Route: 'oral',
        Frequency: '4 hrs',
        Start_date: '2021-10-10',
        End_date: '2021-10-20',
      },
        {
        Trade_name: 'Amoxicillin',
        Salt: 'amoxil',
        Number: '250',
        Unit: 'mg',
        Route: 'oral',
        Frequency: '4 hrs',
        Start_date: '2021-10-10',
        End_date: '2021-10-20',
      },
      ],
    allergies: [
      {
        Allergen: "Rice",
        Allergen_Type: "Food",
        Reaction: "Causes swelling of hands",
        category: "Intolerance",
        Start_date: '2021-10-10',
        End_date: '2021-10-20',
      },
      {
        Allergen: "Black Chickpease",
        Allergen_Type: "",
        Reaction: "causes indigestion",
        category: "",
        Start_date: '2021-10-10',
        End_date: '2021-10-20',
      },
      {
        Allergen: "Pollen",
        Allergen_Type: "Environmental",
        Reaction: "causes skin hives",
        category: "Allergy",
        Start_date: '2021-10-10',
        End_date: '2021-10-20',
      },
      {
        Allergen: "Perfume",
        Allergen_Type: "Chemical",
        Reaction: "causes dizziness",
        category: "Sensitivity",
        Start_date: '2021-10-10',
        End_date: '2021-10-20',
      },
      ],
    pastMedicalHx: [
      {
        Disease: "Diabeties",
        Specification: "Type 2",
        Onset: "2021-10-10",
        Positive_Tags: ["Neuropathy", "Diet Control", "Vascular Complications"],
        Negative_Tags: ["Retinopathy", "Nephropathy"],
        Neutral_Tags: ["Since 1986", "2 Foot Amputations"],
        Tag_Keywords: {
          Neuropathy: ["Numbness", "Tingling", "Pain"],
          Diet_Control: ["Carb Counting", "Portion Control", "Regular Exercise"],
          Vascular_Complications: ["Hypertension", "Hyperlipidemia", "Coronary Artery Disease"],
        },
        Organs_Affected: [],
      },
      {
        Disease: "Hypertension",
        Specification: "Stage 2",
        Onset: "2021-10-10",
        Positive_Tags: ["Headaches", "Dizziness", "Fatigue"],
        Negative_Tags: ["Chest Pain", "Shortness of Breath"],
        Neutral_Tags: ["Since 2010", "Regular Medication"],
        Tag_Keywords: {
          Headaches: ["Frontal", "Occipital", "Temporal"],
          Dizziness: ["Vertigo", "Lightheadedness"],
          Fatigue: ["Generalized", "Postural"],
        },
        Organs_Affected: [],
      },
      {
        Disease: "Asthma",
        Specification: "Mild Persistent",
        Onset: "2021-10-10",
        Positive_Tags: ["Wheezing", "Cough", "Shortness of Breath"],
        Negative_Tags: ["Chest Pain", "Hemoptysis"],
        Neutral_Tags: ["Since 2000", "Regular Medication"],
        Tag_Keywords: {
          Wheezing: ["Day", "Night"],
          Cough: ["Dry", "Productive"],
          Shortness_of_Breath: ["Exertional", "At Rest"],
        },
        Organs_Affected: [],
      },
      {
        Disease: "GERD",
        Specification: "Severe",
        Onset: "2021-10-10",
        Positive_Tags: ["Heartburn", "Regurgitation", "Dysphagia"],
        Negative_Tags: ["Chest Pain", "Hematemesis"],
        Neutral_Tags: ["Since 2005", "Regular Medication"],
        Tag_Keywords: {
          Heartburn: ["Postprandial", "Nocturnal"],
          Regurgitation: ["Food", "Bile"],
          Dysphagia: ["Solids", "Liquids"],
        },
        Organs_Affected: [],
      },
    ],
      // Add more bins and their fields
    };
  
    return emptyBins[bin] || {};
  }

  export const getEmptyBins = (bin: string) => {
    const emptyBins: any = {
      socialHx: {
        Personal: {
        Marital_Status: '',
        Occupation_and_Education : '',
        },
        LifeStyle: {
        Dietary_Habits: '',
        Physical_Activity: '',
        Routine: '',
        Sleep_Routine: '',
        Stresses: '',
        },
      },
      meds: {
        Trade_name: '',
        Salt: '',
        Number: '',
        Unit: '',
        Route: '',
        Frequency: '',
        Start_date: '',
        End_date: '',
      },
      // Add more bins and their fields
    };
  
    return emptyBins[bin] || {};
  }

  export const getFieldOptions = (bin: string, field: string) => {
    console.log("getFieldOptions called for: ", field);
    field = field.replace(/ /g, '_');
    const options: any = {
      socialHx: {
        Marital_Status: ['Single', 'Married', 'Divorced or Separated', 'Widowed', 'Other'],
        Identity: ['Heterosexual', 'Homosexual', 'Other'],
        Diet_Pattern: ['Vegetarian', 'Vegan', 'Paleo', 'Keto', 'Mediterranean', 'Low Carb', 'Low Fat', 'High Protein', 'Other'],
        Exercise: ['Cycling', 'Walking', 'Running or Jogging', 'Yoga or Plates', 'Strength Training', 'No Exercise', 'Other'],
        Routine: ['Remains the same most days', 'Changes once a week', 'Changes every 2-3 days', 'Changes every day', 'Other'],
        Sleep_Pattern: ['Poor sleep every night', 'Bad sleep', 'No sleep', 'Good sleep every night'],
        Stress_Level: ['High Stress', 'Medium Stress', 'Low Stress'],
        Occupation: ['Employed', 'Unemployed', 'Student', 'Retired', 'Home Maker', 'Other'],
        Occupation_Type: ['Full-time', 'Part-time', 'Freelance/Contract', 'Other' ],
        Education: ['Under-Matric', 'Matric/O level', 'FSC/A level', 'Bachelors', "Post-graduation"],
        Diet_Specifics: ['No Meat', 'No Grains', 'No Dairy', 'No Sugar', 'No Processed Foods', 'Other'],
        Exercise_Frequency: ['6 days', '5 days', '4 days', '3 days', '2 days', '1 day', 'None'],
        Exercise_Duration: ['45 minutes', '30 minutes', '60 minutes', '90 minutes', '120 minutes', 'other'],
        Sleep_Specifics: ['Patient has sleep apnea', 'Difficulty Falling alseep', 'Waking up during the night and having trouble falling back asleep', 'Waking up too early and being unable to fall back asleep'],
        Sleep_Quality: ['Poor sleep quality', 'Good sleep quality', 'No sleep quality'],
        Sleep_Duration: ['Less than 4 hours', '4-6 hours', '6-8 hours', '8+ hours'], 
        Stress_Reason: ['Medical condition', 'Headaches', 'Indigestion', 'Insomnia', 'Fatigue', 'Muscle Cramps', 'None', 'Other'],
        Stress_Management: ['Meditation', 'Talking to a friend', 'Making time for hobbies', 'Breathing Techniques', 'Therapy', 'None', 'Other'],
      },
      meds: { //to be filled by abdullah
        Trade_name: ['Aspirin', 'Ibuprofen', 'Acetaminophen', 'Metformin', 'Lisinopril'],
        Salt: ['Sodium Chloride', 'Potassium Chloride', 'Calcium Carbonate', 'other'],
        Number: ['1', '2', '3', '4', 'other'],
        Unit: ['mg', 'g', 'ml','other'],
        Route: ['Oral', 'Intravenous', 'Intramuscular', 'Subcutaneous', 'Topical', 'Inhalation', 'other'],
        Frequency: ['Once daily', 'Twice daily', 'Three times daily', 'Every 4 hours', 'Every 6 hours', 'Every 8 hours', 'Every 12 hours', 'other'],
        Start_date: ['YYYY-MM-DD', 'Today', 'other'],
        End_date: ['YYYY-MM-DD', 'Ongoing', 'other'],
      },
      allergies: {
        Food_Allergens: ["Milk", "Eggs", "Peanuts", "Tree nuts (eg. almonds, walnuts, cashews, pistachios, hazelnuts)", "Fish", "Shellfish (eg. shrimp, crab, lobster)", "Soy", "Wheat", "Gluten", "Sesame", "Soybean", "Mustard", "Artificial Food Coloring", "Sulfites (found in dried fruits)", "Gelatin", "Legumes (eg. lentils, peas)", "Other"],
        Environmental_Allergens: ["Pollen", "Mold", "Dust mites", "Animal dander", "Insect bites", "Plant contact (eg. Poison ivy, Poison oak, Poison sumac)", "Other"],
        Chemical_Allergens: ["Detergents", "Bleach", "Ammonia", "Synthetic fragrances", "Essential oils", "Cosmetics", "Latex", "Industrial chemicals (eg. Nickel, Chromium, Cobalt)", "Other"],
        Drug_Class: ["Antibiotics", "NSAIDs", "Anticonvulsants", "Antidiabetics", "Local anesthetics", "Anticoagulants", "Antivirals", "Corticosteroids", "Antipsychotics", "Other"],
        Category: ["Allergy", "Intolerance", "Sensitivity", "Adverse drug reaction"],
        Allergen_Type: ["Food", "Environmental", "Chemical", "Drug Formulation", "Drug Ingredient", "Drug Class"],
        Reaction: ["Anaphylaxis", "Anxiety", "Atopic Dermatitis", "Contact Dermatitis", "Cough", "Diarrhea", "Dizziness", "Drowsiness", "Itching", "Seizures", "Swelling", "Photosensitivty", "Hives", "Other"],
        Start_Date: ["YYYY-MM-DD", "Today", "other"], 
        End_Date: ["YYYY-MM-DD", "Ongoing", "other"],
        Carry_Epipen: ["Yes", "No"],
        Used_Epipen: ["Yes", "No"],
        Last_Used_Epipen: ["YYYY-MM-DD", "Never", "other"],
      },
      pastMedicalHx: {
        Disease: ["Diabetes Type 1", "Diabeties Type 2", "Asthma", "High Cholesterol", "Coronary Artery Disease", "Thalassemia", "Hyperthyroidism", "Anemia", "PCOS (Polycystic Ovary Syndrome)", "Chronic Kidney Disease", "Chronic Liver Disease", "Hypertension", "Tuberculosis", "Ischemic Stroke", "Osteoarthitis", "Hepitits B", "Hepititis C"],
        TagColors: ["Red", "Green", "Blue", "Gray"],
      }
      // Add more bins and their fields
    };
  
    return options[bin]?.[field] || [];
  };

