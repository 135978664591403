import { useDispatch, useSelector } from "react-redux";
import { UpdateMedBin } from "../state/MedicineBinSlice";
import { RootState } from "../state/reducers";

function useMedicineBin() {
  const dispatch = useDispatch();
  const MedicineBin = useSelector((state: RootState) => {
    return state.MedicineBin;
  });

    const updateMedicineBin = (medicine: any) => {
        dispatch(UpdateMedBin(medicine));
    };  

  return {
    MedicineBin,
    updateMedicineBin
  };
}

export default useMedicineBin;